import { head } from "lodash";
import ApiService from "@/core/services/api.service";

export const UPDATE_ENGINEER_STATUS = "trackingUpdateEngineerStatus";
export const FILTER_ENGINEER = "trackingFilterEngineer";
export const SET_LOADER = "trackingSetLoader";
export const SET_ENGINEERS = "trackingSetEngineers";
export const GET_ENGINEERS = "trackingGetEngineers";
export const GET_ENGINEER_STATUS = "trackingGetEngineerStatus";

const engineerStatusList = [
  {
    text: "All Engineers",
    value: "all",
    count: 0,
    color: "cyan",
  },
  {
    text: "Active Engineers",
    value: "active",
    count: 0,
    color: "green",
  },
  {
    text: "In-Active Engineers",
    value: "in-active",
    count: 0,
    color: "red",
  },
];

const visitStatusList = [
  {
    text: "Unassigned Jobs",
    value: "all",
    count: 10,
    color: "cyan",
  },
  {
    text: "Assigned Jobs",
    value: "active",
    count: 7,
    color: "green",
  },
  {
    text: "Open Jobs",
    value: "active",
    count: 7,
    color: "green",
  },
  {
    text: "In-Progress Jobs",
    value: "active",
    count: 7,
    color: "green",
  },
  {
    text: "Check-in Jobs",
    value: "active",
    count: 7,
    color: "green",
  },
];

const state = {
  dbVisitStatusList: visitStatusList,
  dbVisitStatus: head(visitStatusList),
  dbVisits: [],
  dbVisitLoading: false,
  dbEngineerStatusList: engineerStatusList,
  dbEngineerStatus: head(engineerStatusList),
  dbEngineers: [],
  dbEngineerLoading: false,
};

const getters = {
  visitStatusList: (state) => state.dbVisitStatusList,
  visitStatus: (state) => state.dbVisitStatus,
  visits: (state) => state.dbVisits,
  visitLoading: (state) => state.dbVisitLoading,
  engineerStatusList: (state) => state.dbEngineerStatusList,
  engineerStatus: (state) => state.dbEngineerStatus,
  engineers: (state) => state.dbEngineers,
  engineerLoading: (state) => state.dbEngineerLoading,
};

function getEngineers(query) {
  return new Promise((resolve, reject) => {
    ApiService.setHeader();
    ApiService.query("tracking/engineers", query)
      .then((response) => {
        resolve(response?.data?.data ?? []);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

const actions = {
  [FILTER_ENGINEER](context, query) {
    const status = context.state.dbEngineerStatus;
    context.state.dbEngineerLoading = true;
    context.commit(SET_ENGINEERS, []);
    getEngineers({ ...query, status: status?.value ?? "all" })
      .then((response) => {
        context.commit(SET_ENGINEERS, response);
      })
      .finally(() => {
        context.state.dbEngineerLoading = false;
      });
  },
  [GET_ENGINEER_STATUS](context) {
    ApiService.setHeader();
    ApiService.get("tracking/engineers/status").then((response) => {
      const payload = response?.data?.data ?? [];
      context.state.dbEngineerStatusList = payload;
      context.state.dbEngineerStatus = head(payload);
    });
  },
};

const mutations = {
  [UPDATE_ENGINEER_STATUS](state, payload) {
    state.dbEngineerStatus = payload;
  },
  [SET_ENGINEERS](state, payload) {
    state.dbEngineers = payload;
  },
  [SET_LOADER](state, payload) {
    state.dbEngineerLoading = payload;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
