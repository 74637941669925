<template>
  <div justify="center">
    <v-dialog persistent :value="connectionDialog" max-width="1000">
      <v-card
        width="100%"
        class="white-backgroun"
        style="
          background-image: url(/No_net.7d12dd3f.png);
          background-repeat: no-repeat;
          background-size: cover;
        "
      >
        <div class="text-center py-15">
          <v-img max-width="300" class="mx-auto" src="/gif-3.gif"></v-img>
          <div
            class="text-h1 font-weight-700 text-uppercase py-8"
            style="font-family: cursive !important"
          >
            Oops !
          </div>
          <div class="d-flex align-center justify-content-center">
            <v-img max-width="40" src="/media/error/empty.png"></v-img
            ><span class="text-h4 text-muted ms-2"
              >No internet connection found.</span
            >
          </div>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "No-Connection",
  props: {
    connectionDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOffline: false,
    };
  },
};
</script>
